// core library imports
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// third-party imports
import axios from "axios";
import { useFormik } from 'formik';

// local imports
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Oval from "../Loader/CircleLoade";

/**
 * This page is dedicated for appproval of request pending from vouchgram.
 * HO user can accept or reject vouchegram submitted request
 */
export default function RedemptionApproval() {
  // session storage component
  let token = localStorage.getItem("token");
  const userType = localStorage.getItem("user_type");

  // useState hooks
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate("/");
    else {
      try {
      } catch (error) {
        console.log(error)
      }
    }
  }, []);

  return (
    <>
      {loading ? <Oval /> : null}
      <Header />
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <Link to="" role="tab" data-toggle="tab">
                    Redemption Approval{" "}
                  </Link>
                </li>
                <li>
                  FILTER
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </section>
      <Footer />
    </>
  );
}
