import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import loginBanner from '../../../assets/img/loginbanner.jpg'
import axios from 'axios';
import * as Constants from "../../../Constants/index"
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import './loginStyle.css'
import whitearrow from '../../../assets/img/whitearrow.svg'
export default function Login() {
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();



    useEffect(() => {
        const userType = localStorage.getItem('user_type');
        if (userType === 'SALES') {
            navigate('/dashboardSales')
        } else if (userType === 'RETAILER') {
            navigate('/dashboard_retailer')
        } else if (userType === 'HO') {
            navigate('/dashboardAdmin')
        }
    }, [])
    const toggleShowPassword = () => {
        if (showPassword === false) {
            setShowPassword(true)
        } else {
            setShowPassword(false)
        }
    }
    const validateForm = () => {
        let errors = {};

        if (!mobile) {
            errors.mobile = 'User ID is required';
        } else if (!/^\d+$/.test(mobile)) {
            errors.mobile = 'User ID must be numeric';
        } else if (mobile.length !== 10) {
            errors.mobile = 'User ID must be 10 digits';
        }

        if (!password) {
            errors.password = 'Password is required';
        }

        return errors;
    };

    const handleLogin = async () => {
        setErrors({});

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }
        try {
            await axios.post(Constants.baseUrl + 'auth/login-password',
                {
                    mobile: mobile,
                    password: password,
                    fcmToken:''
                },
                {
                    headers: {
                        'Accept-Language': localStorage.getItem('language'),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    },
                }
            ).then((res) => {
                const dt = res.data.output
                if (res.status === 200) {
                    if (res.data.code === 10001) {
                        if (res.data.output.user.userType === 'SALES') {
                            localStorage.setItem('token', dt?.access?.token);
                            localStorage.setItem('user_id', dt?.user?.id);
                            localStorage.setItem('user_type', dt?.user?.userType);
                            localStorage.setItem('user_name', dt?.user?.name);
                            localStorage.setItem('user_email', dt?.user?.email);
                            localStorage.setItem('user_mobile', dt?.user?.mobile);
                            localStorage.setItem('userUniq_id', dt?.user?.userUniqid);
                            navigate('/dashboardsales')
                        } else if (dt?.user.userType === 'RETAILER') {
                            localStorage.setItem('token', dt?.access?.token);
                            localStorage.setItem('user_id', dt?.user.id);
                            localStorage.setItem('user_type', dt?.user.userType);
                            localStorage.setItem('user_name', dt?.user.name);
                            localStorage.setItem('user_email', dt?.user.email);
                            localStorage.setItem('user_mobile', dt?.user.mobile);
                            localStorage.setItem('userUniq_id', dt?.user.userUniqid);
                            localStorage.setItem('gst', dt?.user.gst);
                            navigate('/dashboard_retailer')
                        } else if (dt?.user.userType === 'HO') {
                            localStorage.setItem('token', dt?.access?.token);
                            localStorage.setItem('user_id', dt?.user?.id);
                            localStorage.setItem('user_type', dt?.user?.userType);
                            localStorage.setItem('user_name', dt?.user?.name);
                            localStorage.setItem('user_email', dt?.user?.email);
                            localStorage.setItem('user_mobile', dt?.user?.mobile);
                            localStorage.setItem('userUniq_id', dt?.user?.userUniqid);
                            localStorage.setItem('gst', dt?.user?.gst);
                            navigate('/dashboardAdmin')
                        } else {
                            swal({
                                text: 'You are not a Sales Team Member',
                                icon: 'error',
                                buttons: false,
                                timer: 2000
                            });
                        }
                    } else if (res.data.code === 16001) {
                        swal({
                            text: res.data.msg,
                            buttons: false,
                            timer: 2000,
                        });
                    } else {
                        swal({
                            text: res.data.msg,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                } else {
                    swal({
                        text: res.data.msg,
                        buttons: false,
                        timer: 2000,
                    });
                }
            });

        } catch (error) {
            console.log(error);
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <>
            <Header />
            <section className="loginsec mt134 bglogin" style={{ backgroundImage: `url(${loginBanner})`, }}>
                <div className="container">
                    <div className="loginwindow">
                        <div className="row mr0">
                            <div className="col-md-8 col-md-offset-2 col-xs-12 loginside">
                                <div className="formlogin">

                                    <h2>Log In</h2>
                                    <div className="form-group form-group-mb">
                                        <label htmlFor="user id">User ID</label>
                                        <input type="text" value={mobile} maxLength="10" onChange={(event) => {
                                            const input = event.target.value;
                                            if (/^\d*$/.test(input)) {
                                                setMobile(input);
                                            }
                                        }} placeholder="User ID" className="form-control frm" onKeyDown={handleKeyDown} />
                                    </div>
                                    {errors.mobile && (
                                        <div className="error-message-login">{errors.mobile}</div>
                                    )}
                                    <div className="form-group form-group-mb">
                                        <label htmlFor="review">Password</label>
                                        <span className="showpass" title="Show Password" onClick={toggleShowPassword}>
                                            {showPassword === false ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}</span>
                                        <input type={showPassword === true ? "text" : "password"} id="myInput" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Enter Password" className="form-control frm" onKeyDown={handleKeyDown} />
                                    </div>
                                    {errors.password && (
                                        <div className="error-message-login">{errors.password}</div>
                                    )}
                                    <div className="form-group mtop20">
                                        {/* <input type="submit" value="LOGIN" onClick={handleLogin} className="btn loginbuttn" /> */}
                                        <button type="submit" className="btn loginbuttn" onClick={handleLogin}>LOG IN <img className="logarrow" src={whitearrow} alt="" /></button>
                                    </div>
                                    <div className="forgtPassword">
                                        <Link to="/forgot_password">Forgot password ?</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
